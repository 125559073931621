import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { format } from 'date-fns';
import { ProgressSpinner } from 'primereact/progressspinner';
import { motion } from 'framer-motion';


const Manage = () => {

  const [userId, setUserId] = useState(null);
  const [response, setResponse] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const toast = useRef(null);

  const backendUrl = window.location.origin;

  const columns = [
    { field: 'date', header: 'Date' },
    { field: 'topic', header: 'Topic' },
    { field: 'participantLimit', header: 'Limit' },
    { field: 'participantLevel', header: 'Level' }
  ];

  const participantLimits = new Map();
  participantLimits.set('2', 2);
  participantLimits.set('3', 3);
  participantLimits.set('4 Optimal', 4);
  participantLimits.set('5', 5);
  participantLimits.set('6', 6);
  participantLimits.set('7', 7);
  participantLimits.set('8', 8);
  participantLimits.set('9', 9);

  const participantLevels = new Map();
  participantLevels.set('A1 Beginner', 'A1');
  participantLevels.set('A2 Elementary', 'A2');
  participantLevels.set('B1 Intermediate', 'B1');
  participantLevels.set('B2 Upper-Intermediate', 'B2');
  participantLevels.set('C1 Advanced', 'C1');
  participantLevels.set('C2 Proficiency', 'C2');

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
        setUserId(window.Telegram.WebApp.initDataUnsafe.user.id);
      }
    } else {
      show("Error", "Can't get speaking club list", "We can't get speaking club list, please try againg later");
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchSpeakingClubs();
    }
  }, [userId]);

  const fetchSpeakingClubs = async () => {
    setShowSpinner(true);
    const res = await axios.get(backendUrl + '/upcoming/clubs', {
      params: {
        userId: userId
      }
    })
      .then(response => {
        setResponse(response.data);
        setProducts(response.data)
      })
      .catch(async error => {
        //To avoid screen blinking
        await new Promise(resolve => setTimeout(resolve, 500));
        show("Error", "Can't get speaking club list", "We can't get speaking club list, please try againg later");
      })
      .finally(() => {
        setShowSpinner(false);
      }
      );
  }

  const formatDate = (instant) => {
    const date = new Date(instant);
    return format(date, 'dd/MM/yy HH:mm');
  };

  const isPositiveInteger = (val) => {
    let str = String(val);

    str = str.trim();

    if (!str) {
      return false;
    }

    str = str.replace(/^0+/, '') || '0';
    let n = Math.floor(Number(str));

    return n !== Infinity && String(n) === str && n >= 0;
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case 'quantity':
      case 'price':
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options) => {
    if (options.field === 'date')
      return dateEditor(options);
    if (options.field === 'participantLimit')
      return dropdownEditor(options);
    else
      return textEditor(options);
  };

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
  };

  const dateEditor = (options) => {
    return <Calendar value={options.value} onChange={(e) => options.editorCallback(e.value)} showTime hourFormat="24" touchUI />;
  };

  const dropdownEditor = (options) => {
    return <Dropdown value={options.value} onChange={(e) => options.editorCallback(e.value)} options={Array.from(participantLimits.keys())} />
  };

  const dateTemplate = (rowData) => {
    if (rowData === null || rowData.date === null) {
      return '0';
    }
    return formatDate(rowData.date);
  };

  const participantLimitTemplate = (rowData, field) => {
    if (rowData === null || rowData.participantLimit === null) {
      return '0';
    }
    return String(rowData.participantLimit);

  };

  const accept = async () => {
    const res = await axios.post(backendUrl + '/club/remove', selectedProduct)
      .then(response => {
        fetchSpeakingClubs();
      })
      .catch(error => {
        show("Error", "Can't remove speaking club", "We can't remove speaking club, please try againg or text to admin");
        setShowSpinner(false);
      })
      .finally(() => {
        setShowSpinner(false);
      }
      );
  }

  const show = (severity, summary, detail) => {
    toast.current.show({ severity: severity, summary: summary, detail: detail, life: 4000 });
  };

  const reject = () => {
    // toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  }

  const confirmRemove = () => {
    confirmDialog({
      message: 'Do you want to cancel this Speaking Club?',
      header: 'Cancel Speaking Club',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept,
      reject
    });
  };

  return (
    <div className='card-main'>

      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h1>Your Speaking clubs</h1>
        <Toast ref={toast} position="top-center" />
        <ConfirmDialog />
        {selectedProduct && (
          <div className="align-left flex flex-wrap justify-content-center gap-3">
            <Button onClick={confirmRemove} rounded text label="Remove" icon="pi pi-times" />
            <Button rounded text label="Edit" disabled />
          </div>
        )}

        {!selectedProduct && (
          <div className="align-left flex flex-wrap justify-content-center gap-3">
            <Button rounded text label="Remove" icon="pi pi-times" disabled />
            <Button rounded text label="Edit" disabled />
          </div>
        )}
        <div className="card flex flex-wrap gap-3 p-fluid">
          {/* <DataTable value={products} editMode="cell" paginator rows={5} rowsPerPageOptions={[5, 10]} >
              <Column field="date" header="Date" body={dateTemplate} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
              <Column field="participantLimit" header="Limit" body={participantLimitTemplate} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
              <Column field="participantLevel" header="Level" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
              <Column field="topic" header="Topic" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
              <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" />
            </DataTable> */}

          <DataTable value={products} selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10]} >
            <Column field="date" header="Date" body={dateTemplate} ></Column>
            <Column field="participantsLimit" header="Limit"></Column>
            <Column field="participantLevel" header="Level"></Column>
            <Column field="topic" header="Topic"></Column>
          </DataTable>
        </div>

        {showSpinner && (
          <div className="spinner-container">
            <ProgressSpinner />
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Manage;
