import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import '../App.css';
import "primereact/resources/themes/viva-light/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Congratulations from '../components/Congratulations';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { FloatLabel } from 'primereact/floatlabel';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

const Create = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [participantLimit, setParticipantLimit] = useState(null);
  const [participantLevel, setParticipantLevel] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [visibleRandomPicker, setVisibleRandomPicker] = useState(null);
  const [visibleCustomPicker, setVisibleCustomPicker] = useState(null);
  const [topic, setTopic] = useState(null);
  const [customTopic, setCustomTopic] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState(null);
  const toast = useRef(null);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const backendUrl = window.location.origin;

  const participantLimits = new Map();
  participantLimits.set('2', 2);
  participantLimits.set('3', 3);
  participantLimits.set('4 Optimal', 4);
  participantLimits.set('5', 5);
  participantLimits.set('6', 6);
  participantLimits.set('7', 7);
  participantLimits.set('8', 8);
  participantLimits.set('9', 9);

  const participantLevels = new Map();
  participantLevels.set('A1 Beginner', 'A1');
  participantLevels.set('A2 Elementary', 'A2');
  participantLevels.set('B1 Intermediate', 'B1');
  participantLevels.set('B2 Upper-Intermediate', 'B2');
  participantLevels.set('C1 Advanced', 'C1');
  participantLevels.set('C2 Proficiency', 'C2');

  const topicOptions = [
    { name: 'Generate topic', value: 'random' },
//    { name: 'Custom topic', value: 'custom' },
  ];

  const randomTopicSelectButtonOptions = [
    { name: 'Regenerate', value: 'regenerate' },
    { name: 'Confirm', value: 'confirm' },
  ];

  const customPickerOptions = [
    { name: 'Confirm', value: 'confirm' },
  ];

  useEffect(() => {
    // Check if Telegram WebApp object is available
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
      setUserId(window.Telegram.WebApp.initDataUnsafe.user.id);
    }

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    setMinDate(new Date());

    let max = new Date();

    max.setMonth(nextMonth);
    max.setFullYear(nextYear);
    setMaxDate(max);
  }, []);

  // Function to handle showing congratulations and then closing the app
  const handleCongratulations = () => {
    setShowCongratulations(true);

    // Close the Mini App window after a delay (e.g., 3 seconds)
    setTimeout(() => {
      closeMiniApp();
      clearState();
      setShowCongratulations(false);
    }, 5000);
  };

  const clearState = () => {
    setSelectedDate(null);
    setParticipantLimit(null);
    setParticipantLevel(null);
    setTopic(null);
    setCustomTopic(null);
    setTopicId(null);
    setSelectedQuestions(null);
  };

  const closeMiniApp = () => {
    console.log("Closing the Mini App window");
    window.Telegram.WebApp.close();
  };

  const sendDataToBot = () => {
    //TODO check if telegram object is available otherwise ask for log in
    // if (window.Telegram && window.Telegram.WebApp && userId) {

    if (topic === null && customTopic === null) {
      show("Error", "Required info is missing", "Fill out the topic");
      return;
    }

    if (selectedDate === null || participantLevel === null || participantLimit === null) {
      show("Error", "Required info is missing", "Fill out all the fields");
      return;
    }

    if (window.Telegram) {
      const data = {
        date: selectedDate,
        topic: topic ? topic : customTopic,
        topicId: topicId,
        selectedQuestions: selectedQuestions,
        participantsLimit: participantLimits.get(participantLimit),
        participantLevel: participantLevels.get(participantLevel),
        creatorId: userId,
        provider: 'TELEGRAM'
      };

      setShowSpinner(true)
      axios.post(backendUrl + '/club', data)
        .then(response => {
          handleCongratulations();
        })
        .catch(error => {
          show("Error", "Can't create speaking club", "We can't create speaking club, please try againg or text to admin");
          setShowSpinner(false);
        })
        .finally(() => {
          setShowSpinner(false);
        }
        );
    }
  };

  const handleRandomTopicClick = async () => {
    try {
      console.log(window.location.href);
      const res = await axios.get(backendUrl + '/topic/random', {
        params: {
          userId: userId
        }
      });
      if (res === null || res.data === null || res.data.name === null || res.data.questions === null || res.data.id === null) {
        throw new Error('Invalid response');
      }
      setTopic(res.data.name);
      setSelectedQuestions(res.data.questions);
      setTopicId(res.data.id);
    } catch (error) {
      //To avoid screen blinking
      await new Promise(resolve => setTimeout(resolve, 500));

      setVisibleRandomPicker(false)
      show("Error", "Error generation topic", "We can't generate topic, please try againg or select custom option");
    } finally {
      setShowSpinner(false)
    }
  };

  const handleTopicOption = (selectedOption) => {
    if (selectedOption === 'custom') {
      setVisibleCustomPicker(true);
      return;
    }

    if (selectedOption === 'random') {
      setVisibleRandomPicker(true)
      if (topicId === null) {
        setShowSpinner(true)
        handleRandomTopicClick();
      }
      return;
    }
  };

  const handleRandomTopic = (selectedOption) => {

    if (selectedOption === 'confirm') {
      setCustomTopic(null);
      setVisibleRandomPicker(false);
      return;
    }

    if (selectedOption === 'regenerate') {
      setTopic('');
      handleRandomTopicClick();
      return;
    }
  };

  const handleCustomTopic = (selectedOption) => {
    setCustomTopic(selectedOption);
    setTopic('');
    setTopicId(null);
    setSelectedQuestions(null);
  };

  const handleCustomTopicSelectButtonClick = (selectedOption) => {
    if (selectedOption === 'confirm') {
      setVisibleCustomPicker(false);
      return;
    }
  };

  const cleanSelectedTopicData = () => {

  };

  const show = (severity, summary, detail) => {
    toast.current.show({ severity: severity, summary: summary, detail: detail, life: 4000 });
  };

  return (
    <div className='card-main'>

      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >

        <h1 className='h1-margin'>Create you own speaking club! 🚀</h1>

        <div className="card flex flex-wrap gap-3 p-fluid">
          <div className="flex-auto">
            <FloatLabel className="float-label-margin">
              <Calendar inputId="club_date" value={selectedDate} onChange={(e) => setSelectedDate(e.value)} showIcon showTime hourFormat="24" minDate={minDate} maxDate={maxDate} readOnlyInput touchUI />
              <label htmlFor="club_date" className="font-bold block mb-2">Date</label>
            </FloatLabel>
          </div>

          <div className="flex-auto">
            <FloatLabel className="float-label-margin">
              <Dropdown id='participants_limit' value={participantLimit} onChange={(e) => setParticipantLimit(e.value)} options={Array.from(participantLimits.keys())} placeholder="Patricipants Limit" className="w-full md:w-14rem" />
              <label htmlFor="participants_limit" className="font-bold block mb-2">Patricipants Limit</label>
            </FloatLabel>
          </div>

          <div className="flex-auto">
            <FloatLabel className="float-label-margin">
              <Dropdown id='participants_level' value={participantLevel} onChange={(e) => setParticipantLevel(e.value)} options={Array.from(participantLevels.keys())} placeholder="Patricipants Level" className="w-full md:w-14rem" />
              <label htmlFor="participants_level" className="font-bold block mb-2">Patricipants Level</label>
            </FloatLabel>
          </div>

          <div className="flex-auto">
            <SelectButton onChange={(e) => handleTopicOption(e.value)} optionLabel="name" options={topicOptions} />
            <Dialog header="Choose topic" visible={visibleRandomPicker} style={{ width: '50vw' }} onHide={() => { if (!visibleRandomPicker) return; setVisibleRandomPicker(false); }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
              <div className="card flex flex-wrap justify-content-center gap-3">
                <SelectButton onChange={(e) => handleRandomTopic(e.value)} optionLabel="name" options={randomTopicSelectButtonOptions} />
              </div>
              {topic ? (
                <div>
                  <h2>{topic}</h2>
                  <div>
                    {selectedQuestions.map((question, index) => (
                      <p key={index}>- {question}</p>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="card">
                  <ProgressSpinner />
                </div>
              )}

            </Dialog>

            <Dialog header="Create custom topic" visible={visibleCustomPicker} style={{ width: '50vw' }} onHide={() => { if (!visibleCustomPicker) return; setVisibleCustomPicker(false); }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
              <div className="card flex flex-wrap justify-content-center gap-3 float-label-margin">
                <SelectButton onChange={(e) => handleCustomTopicSelectButtonClick(e.value)} optionLabel="name" options={customPickerOptions} />
              </div>
              <div className="flex-auto">
                <FloatLabel>
                  <InputText id="customTopic" value={customTopic} onChange={(e) => handleCustomTopic(e.target.value)} aria-describedby="customTopic-help" />
                  <label htmlFor="customTopic">Short Topic Name</label>
                </FloatLabel>
                <small id="customTopic-help">
                  Enter the topic you want to discuss.
                </small>
              </div>
            </Dialog>
          </div>
        </div>

        <button className="pulsing-button" onClick={sendDataToBot} >Create!🤩</button>

        {showCongratulations && <Congratulations />}

        <div className="card flex justify-content-center">
          <div className="flex flex-wrap gap-2">
            <Toast ref={toast} position="top-center" />
          </div>
        </div>

        {showSpinner && (
          <div className="spinner-container">
            <ProgressSpinner />
          </div>
        )}

      </motion.div>
    </div>
  );
};

export default Create;
