import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Create from './pages/Create';
import Manage from './pages/Manage';

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Create />} />
                <Route path="/manage" element={<Manage />} />
            </Routes>

        </Router>
    );
}

export default App;
