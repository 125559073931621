import React from 'react';
import '../css/Congratulations.css';

const Congratulations = () => {
    return (
        <div className="congratulations-container">
            <h2>Speaking club created!</h2>
            <h3>Bot will send you a message with details soon.</h3>
        </div>
    );
};

export default Congratulations;
